export const DASHBOARD_MODULE = "Dashboard";

// Data Grid Configuration
/**
 * ROW LAYOUTS describes different types of row sizes
 * @typedef ROW_LAYOUTS
 * @property {string} layoutName
 */
export const ROW_LAYOUTS = ["Roomy", "Comfortable", "Compact"];
/**
 * PAGE SIZE describes different types of page size lengths
 * @typedef PAGE_SIZE
 * @property {string} size
 */
export const PAGE_SIZE = ["10", "100", "500", "1000"];

// #region Local Storage
/**
 * NOTIFICATION KEY
 * @typedef NOTIFICATION_KEY
 * @property {string} value
 */
export const NOTIFICATION_KEY = "Core.Notifications";
// #endregion

// Work box settings
/**
 * APPLICATION SHELL
 * @typedef APPLICATION_SHELL
 * @property {string} value
 */
export const APPLICATION_SHELL = "application-shell";
// #region Index Db Databases

/**
 * Dashboard
 *   @typedef DASHBOARD
 * @property {string} value
 */
export const DASHBOARD = "Dashboard";
/**
 * Application Configurations
 *   @typedef CORE_STORE
 * @property {string} value
 */
export const CORE_STORE = "Core";
/** Facilities */
export const FACILITIES_MODULE = "FACILITIES";
export const FACILITY_CODE = "Core.FacilityCode";
export const FACILITY_AUTHORIZATION = "Core.FacilityAuth";

/** Facility Lookup */
/** Settings */
export const ROW_LAYOUT = "Core.DataGrid.RowLayout";
export const DEFAULT_LIMIT = "Core.DataGrid.ItemsPerPage";
export const DROPDOWN_FILTER_THRESHOLD = 10;

/**
 *
 * Named Filter Key used for saving and receiving app configurations
 */
export const WR_NAMED_FILTER = "WR.NamedFilter";

/** App Configs */
export const APP_CONFIG_GLOBAL = "Global App Config";
export const APP_CONFIG_FACILITY = "Facility App Config";
export const APP_CONFIG = "App Config";
export const APP_CONFIG_MODIFICATION = "Modify_App_Config";
export const LISTING_LIMIT = 10;
// #endregion

/**
 * GP QUEUE
 * @typedef SYNC_QUEUE
 * @property {string} value
 */
export const SYNC_QUEUE = "SYNC_Queue";

/**
 * ASSETS IMAGES
 * @typedef ASSETS_IMAGES
 * @property {string} value
 */
export const ASSETS_IMAGES = "assets-images";

/**
 * Const for lookups
 * Lookup_Selection_Mode used to for single/multiple selection of DataTable item
 * Equipment_LookUp_Mode used to configure things according to look or equipment module
 */
export const LookUp_Mode = {
  LookUp: "LookUp",
  Page: "page",
};
export const Lookup_Selection_Mode = {
  single: "Single",
  multiple: "Multiple",
};

/**
 * BOOLEAN_VALUES describes boolen values
 * @constant
 * @typedef BOOLEAN_VALUES
 * @property {string} text
 * @property {string} id
 * @return {string} True/False
 */
export const BOOLEAN_VALUES = [
  { label: "(All)", value: "" },
  { label: "Checked", value: "true" },
  { label: "Not Checked", value: "false" },
];

/** Export const Core
 *  @constant
 *  @type {string}
 *  @default
 */
export const Core_Module = "Core";

/** GhaphQL_OPERATORS */
/** Export const CONTAINS_OP
 * @constant {string}
 */
export const CONTAINS_OP = "contains";
/** Export const EQUALS_OP
 * @constant {string}
 */
export const EQUALS_OP = "eq";
/** Export const NOT_EQUALS_OP
 * @constant {string}
 */
export const NOT_EQUALS_OP = "neq";
/** Export const GREATER_THAN_OP
 * @constant {string}
 */
export const GREATER_THAN_OP = "gt";
/** Export const GREATER_THAN_EQUAL_OP
 * @constant {string}
 */
export const GREATER_THAN_EQUAL_OP = "gte";
/** Export const LESS_THAN_OP
 * @constant {string}
 */
export const LESS_THAN_OP = "lt";
/** Export const LESS_THAN_EQUAL_OP
 * @constant {string}
 */
export const LESS_THAN_EQUAL_OP = "lte";
/** Export const Starts_WITH_OP
 * @constant {string}
 */
export const STARTS_WITH_OP = "startswith";
/** Export const ENDS_WITH_OP
 * @constant {string}
 */
export const ENDS_WITH_OP = "endswith";

/** Preferences  */
export const PREFERENCES_TILELAYOUT = "Preferences_TileLayout";

/** Dashboard  */
export const DASHBOARD_TILELAYOUT = "Dashboard_TileLayout";

/** Authorization */
export const ACCESS_TOKEN = "Core.AccessToken";
export const ID_TOKEN = "Core.IdToken";
export const REFRESH_TOKEN = "Core.RefreshToken";
export const SKIP_SPLASH = "Core.SkipSplash";
export const AUTH_TOKENS = "AuthorizationToken";
// Used in User Context
export const TOKEN_EXP = "Core.TokenExp";
export const USER_DATA = "Core.UserData";
export const TENANT_ID = "Core.TenantId";
export const USER_TENANTS = "Core.UserTenants";
export const DEFAULT_TENANT = "Core.DefaultTenant";
/** Export const SYNC_PROCESS_DELAY
 *  @constant
 *  @type {string}
 *  @default
 */

export const SYNC_PROCESS_INTERVAL = {
  /**
   * Save App Config
   */
  APP_CONFIG_MODIFICATION: 120000,
};

/**
 * Default Grid filter operators:
 */
export const FilterOperators = {
  text: [
    { text: "grid.filterContainsOperator", operator: "contains" },
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
    { text: "grid.filterStartsWithOperator", operator: "startswith" },
    { text: "grid.filterEndsWithOperator", operator: "endswith" },
  ],
  numeric: [
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
    { text: "grid.filterGteOperator", operator: "gte" },
    { text: "grid.filterGtOperator", operator: "gt" },
    { text: "grid.filterLteOperator", operator: "lte" },
    { text: "grid.filterLtOperator", operator: "lt" },
  ],
  date: [
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
    { text: "grid.filterAfterOperator", operator: "gt" },
    { text: "grid.filterBeforeOperator", operator: "lt" },
    { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
  ],
  boolean: [
    { text: "grid.filterEqOperator", operator: "eq" },
    { text: "grid.filterNotEqOperator", operator: "neq" },
  ],
};

/**
 * Workbox Startegy
 */
export const WorkboxStartegy = {
  NETWORKFIRST: "networkFirst",
  CACHEFIRST: "cacheFirst",
};

/**
 * Data Types
 */
export const DataTypes = {
  Text: "text",
  TextArea: "textArea",
  Numeric: "numeric",
  Date: "date",
  DateTime: "dateTime",
  Boolean: "boolean",
  MultiSelect: "multiSelect",
  DropDown: "dropDown",
};

/**
 * Form Controls Render Types
 */
export const RenderTypes = {
  ReadOnly: "ReadOnly",
  Disabled: "Disabled",
  Editable: "Editable",
};

/**
 * InLine Row Operation Statuses
 */
export const RowStatus = {
  New: "New",
  Discard: "Discard",
  Deleted: "Deleted",
  Modified: "Modified",
};

export const TokenExpireMessage = [
  "Security token has expired.",
  "Unable to parse JWT token.",
  "Authorization has been denied for this request.",
  "Authorization has been denied for this request.",
];

/**
 * Display notification settings
 */
export const DisplayNotification = {
  SHOW: "Show", // show the notification popup and show notification on notification panel
  SILENT: "Silent", // Do not show the notification popup but notification will be shown in notification panel
  HIDDEN: "Hidden", //  Do not show the notification popup and notification in notification panel
  ALERT: "Alert", // Show the notification popup but no notification will be shown in notification panel.
};

/**
 * Start Autest Modules Constants
 */
// user modules
export const USERS = "Users";
// Autest Load Commands modules
export const LOADCOMMANDS = "LoadCommands";
// Autest Test Arguments modules
export const TESTARGUMENTS = "TestArguments";
// Autest Test Case Arguments modules
export const TESTCASEARGUMENTS = "TestCaseArguments";
// Autest Tests modules
export const TESTS = "Tests";
// Autest Test Steps modules
export const TESTSTEPS = "TestSteps";
// Autest Test Case modules
export const TESTCASE = "TestCase";
// Autest Remote Server modules
export const REMOTESERVERS = "RemoteServers";
// Autest Run Set modules
export const RUNSETS = "RunSets";
// Autest Run Set Arguments modules
export const RUNSETARGUMENTS = "RunSetArguments";
// Autest Execution modules
export const EXECUTIONS = "Executions";
// Autest Execution Result modules
export const EXECUTIONRESULTS = "ExecutionResults";
// Autest Execution Commands modules
export const EXECUTIONCOMMANDS = "ExecutionCommands";
// Autest Execution Field Entries modules
export const EXECUTIONFIELDENTRIES = "ExecutionFieldEntries";
// Autest Execution Screenshots modules
export const EXECUTIONSCREENSHOTS = "ExecutionScreenshots";
// Autest Execution Application Flow modules
export const EXECUTIONAPPLICATIONFLOW = "ExecutionApplicationFlow";
/**
 * end Autest Modules Constants
 */

/**
 * Start RF++ Modules Constants
 */
// RF Profile Group modules
export const RFPROFILEGROUP = "RFProfileGroup";
// RF Profile modules
export const RFPROFILE = "RFProfile";
// RF Profile Setup modules
export const RFPROFILESETUP = "RFProfileSetup";
// RF Form Info modules
export const RFFORMINFO = "RFormInfo";
// RF Field Info modules
export const RFFIELDINFO = "RFFieldInfo";
/**
 * end RF++ Modules Constants
 */

/**
 * Start Organization Modules Constants
 */
// Organization User modules
export const ORGANIZATIONUSERS = "OrganizationUsers";
// Organization UnApproved User modules
export const ORGANIZATIONUNAPPROVEDUSERS = "OrganizationUnapprovedUsers";
/**
 * end Organization Modules Constants
 */

//Exception Status Codes
export const EXCEPTION_STATUS_CODES = [99];

//Alert Types
export const ALERT_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  DAFAULTU: "default",
};

/**
 * Drawer Configuration
 */
export const DrawerOptions = {
  isCollapseOnSelect: false,
};

/** Export const Autest_SUB_MODULES
 *  @constant
 *  @type {string}
 *  @default
 */
export const Autest_SUB_MODULES = {
  Results: "Results",
  Analytics: "Analytics",
};

export const TOGGLE_TEST_ARG_SLIDER = "TOGGLE_TEST_ARG_SLIDER";
export const TOGGLE_RUN_SET_ARG_SLIDER = "TOGGLE_RUN_SET_ARG_SLIDER";
export const TOGGLE_EXECUTION_SLIDER = "TOGGLE_EXECUTION_SLIDER";
export const TOGGLE_PROFILE_SETUP_SLIDER = "TOGGLE_PROFILE_SETUP_SLIDER";
export const TOGGLE_TEST_STEP_SLIDER = "TOGGLE_TEST_STEP_SLIDER";
export const TOGGLE_ADD_ORG_USER_SLIDER = "TOGGLE_ADD_ORG_USER_SLIDER";

/** Export const ROLES  */
export const ROLES = {
  OrganizationAdmin: "companyadmin",
};

/**
 * Constants for RunSet module
 **/
export const RunSet_LOOKUP = "RunSetLOOKUP";
export const Location_Hierarchy = "Location_Hierarchy";
export const Lookup_Hierarchy = "Lookup_Hierarchy";

/** Export const Autest_SUB_MODULES
 *  @constant
 *  @type {string}
 *  @default
 */
export const AUTH_PERMISSION = {
  RF: "SMART_FEATURE_RFPLUSPLUS",
  Autest: "SMART_FEATURE_AUTEST",
  OrganizationAdmin: "companyadmin",
};

/**
 * Constants for Test Github Docs
 **/
export const AUTEST_TEST_GITHUB_RAW_URL =
  "https://raw.githubusercontent.com/OracularIS/autest/main/docs/tests_docs";
export const AUTEST_TEST_GITHUB_REPO_URL =
  "https://github.com/OracularIS/autest/blob/main/docs/tests_docs";

export const TEST_TYPES = ["MOCA", "RF", "WEB"];
export const MTF_PROTOCOL = ["TELNET", "SSH"];
export const RF_FIELD_INFO_TYPES = [
  "cmd",
  "cmd_local",
  "url",
  "pdf",
  "video",
  "img",
];

export const SMART_IS_EMAIL = "@smart-is.com";
